import { Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'
import { useKleoPrice } from '../hooks/useKleoPrice'

export default function KleoPrice() {
  // const { data, isPending, error } = useFetch(
  //   `https://api.coingecko.com/api/v3/simple/price?ids=kleomedes&vs_currencies=usd`,
  // )
  const { data, isLoading, error } = useKleoPrice()

  return (
    <Stat m={6}>
      <StatLabel>$KLEO price</StatLabel>
      <StatNumber color="secondary.base">
        {!isLoading && !error ? <span>${data?.kleomedes.usd}</span> : 'Loading'}
      </StatNumber>
      <StatHelpText>
        <Link isExternal fontWeight={'700'} href={'https://app.osmosis.zone/assets/KLEO'}>
          Osmosis Asset Page
        </Link>
      </StatHelpText>
    </Stat>
  )
}
